<template>
  <div class="footer-application-links">
    <a
      v-for="(link, index) in linksToRender"
      :key="index"
      class="footer-application-links__item"
      :href="link.url"
      :target="link.target"
    >
      <img class="footer-application-links__icon" :src="link.image" alt="" />
    </a>
  </div>
</template>

<script lang="ts" setup>
import type { FooterApplicationLink } from '@/types/store/footer';
import { onMounted, ref } from 'vue';
import { isAndroidDevice, isAppleDevice, OS } from '@/utils/bowser';

const props = defineProps<{
  links: FooterApplicationLink[];
}>();

const isIos = ref(false);
const isAndroid = ref(false);
const getOs = () => {
  let os = '';
  if (isAndroid.value) {
    os = OS.android;
  }
  if (isIos.value) {
    os = OS.iOS;
  }
  return os;
};

const linksToRender = ref<FooterApplicationLink[]>([]);
const renderLinks = () => {
  const os = getOs();
  const links = props.links.filter((link) => link.image && link?.os === os);
  linksToRender.value = links.length > 0 ? links : props.links.filter((link) => link.image);
};

onMounted(() => {
  isIos.value = isAppleDevice();
  isAndroid.value = isAndroidDevice();
  renderLinks();
});
</script>
<style lang="scss" scoped>
.footer-application-links {
  display: flex;
  gap: 8px;
  flex-flow: row wrap;

  &__icon {
    max-height: 40px;
    width: auto;
  }
}
</style>
