import { useStore } from 'vuex';
import type MainState from '@/types/store';
import { computed, inject } from 'vue';
import type { CdekDefaultCity } from '@/types/dto/city';
import { COOKIE_CITY_ID_TAG } from '@/constants/cookie-tag';
import type { VueCookies } from 'vue-cookies';

export const useSelectedCity = () => {
  const $cookies = inject<VueCookies>('$cookies');

  const store = useStore<MainState>();

  const globalCity = computed(() => store.state.city);

  const selectGlobalCity = async (city: CdekDefaultCity) => {
    await store.dispatch('SELECT_CITY', city);
    $cookies?.set(COOKIE_CITY_ID_TAG, city.cdek_id);
  };

  return {
    globalCity,
    selectGlobalCity,
  };
};
