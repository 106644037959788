import { computed } from 'vue';
import { useSelectedCity } from '@/composables/use-selected-city';
import { useSelectGlobalCityModal } from '@/composables/use-select-global-city-modal';
import { useStore } from 'vuex';
import type { FooterData } from '@/types/store/footer';
import useSiteInfo from './use-site-info';

export const useSelectedCityData = () => {
  const store = useStore();

  const { globalCity, selectGlobalCity } = useSelectedCity();
  const { openModal } = useSelectGlobalCityModal();
  const { isRuSite } = useSiteInfo();

  const footer = computed<FooterData>(() => store.getters['footer/footer']);

  const phoneNumber = computed(() => {
    if (isRuSite.value) {
      return globalCity.value.phone || footer.value.feedback?.phone || '';
    }
    return footer.value.feedback?.phone || '';
  });

  const phoneNumberFormatted = computed(() => {
    if (isRuSite.value) {
      return (
        globalCity.value.phone?.replace(/\+7(\d{3})(\d{3})(\d{2})(\d{2})/g, `+7 ($1) $2 $3 $4`) ||
        footer.value.feedback?.phone ||
        ''
      );
    }

    return footer.value.feedback?.phone || '';
  });

  const openCityModal = () => openModal(globalCity.value, selectGlobalCity);

  return {
    openCityModal,
    globalCity,
    phoneNumber,
    phoneNumberFormatted,
  };
};
