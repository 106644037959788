<template>
  <base-cdek-modal :name="modalName" class="select-login-type-modal">
    <div class="select-login-type-modal__title" v-text="$t('loginTypeSelect.title')" />
    <div class="select-login-type-modal__buttons">
      <cdek-button @click="onSelectType(LoginTypes.individual)">
        {{ $t('loginTypeSelect.individual') }}</cdek-button
      >
      <cdek-button theme="outline" @click="onSelectType(LoginTypes.legal)">
        {{ $t('loginTypeSelect.legal') }}</cdek-button
      >
    </div>
  </base-cdek-modal>
</template>
<script setup lang="ts">
import BaseCdekModal from '@/components/BaseComponents/BaseCdekModal.vue';
import { modalName } from '@/components/Modal/SelectLoginTypeModal/consts';
import { CdekButton } from '@cdek-ui-kit/vue';
import { LoginTypes } from '@/components/Login/consts';
import useLogin from '@/components/Login/use-login';
import { useMenuStore } from '@/store/pinia/menu/menu';
import useModal from '@/composables/use-modal';

const { openModalByType } = useLogin();
const menuStore = useMenuStore();
const modal = useModal();

const closeModal = () => {
  modal.hide(modalName);
};

const onSelectType = (type: LoginTypes) => {
  menuStore.closeMobileMenu();
  closeModal();
  openModalByType(type);
};
</script>

<style scoped lang="scss">
.select-login-type-modal {
  :deep(.base-cdek-modal__content) {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 14px;
  }

  &__title {
    margin-bottom: 22px;

    @include headline-5;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}
</style>
