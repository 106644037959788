<template>
  <global-city-modal
    :name="name"
    :label="$t('yourCity')"
    :with-back-button="false"
    with-close-button
    @close="closeModal"
  >
    <div class="select-global-city-modal">
      <cdek-input
        v-model="cityInput"
        class="select-global-city-modal__input"
        small
        hide-tip-till-needed
        clearable
        :placeholder="$t('orderWidget.locality')"
        @update:model-value="fetchItems"
      >
        <template #icons-left>
          <search-icon class="search-form_mobile-text__icon-left" />
        </template>
      </cdek-input>

      <div class="select-global-city-modal__cities-list">
        <div
          v-for="item in citiesToSelect"
          :key="item.id"
          class="select-global-city-modal__city"
          @click="onSelectCity(item)"
        >
          <div
            :class="[{ 'select-global-city-modal__city_green': item.id === globalCity.cdek_id }]"
          >
            {{ item.name }}
          </div>
          <div v-if="item.id === globalCity.cdek_id"><check-icon /></div>
        </div>
      </div>
    </div>
  </global-city-modal>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';

import type { CdekCity, CdekDefaultCity } from '@/types/dto/city';

import GlobalCityModal from '@/components/Modal/GlobalCityModal/GlobalCityModal.vue';
import { useSelectGlobalCityModal } from '@/composables/use-select-global-city-modal';
import { CdekInput, useToast } from '@cdek-ui-kit/vue';
import { useSelectedCity } from '@/composables/use-selected-city';
import useSiteInfo from '@/composables/use-site-info';
import searchIcon from '@/components/Modal/assets/search.svg';
import checkIcon from '@/components/Modal/assets/check.svg';
import { useRestApi } from '@/composables/use-rest-api';
import useCabinetApi from '@/composables/use-cabinet-api';
import { useI18n } from 'vue-i18n';
import debounce from 'lodash/debounce';

const { selectGlobalCity } = useSelectedCity();

defineProps<{ city: CdekDefaultCity }>();
defineEmits<{
  (e: 'onSave', city: CdekDefaultCity): void;
}>();

const { currentLocaleISO, websiteId } = useSiteInfo();

const cityInput = ref('');

const { name, closeModal } = useSelectGlobalCityModal();

const { globalCity } = useSelectedCity();

const { restApi } = useRestApi();

const onSelectCity = async (city: CdekCity) => {
  let newCity: CdekDefaultCity;
  try {
    // пробуем получить полные данные по городу из ещё одной api
    newCity = await restApi.websiteCityGlobalOne(city?.uuid || '', {
      locale: currentLocaleISO.value,
    });
  } catch {
    // или пробуем составить данные из того, что есть
    newCity = {
      ...city,
      cdek_id: city.id,
      country: `${city.countryCode}`,
      country_code: `${city.countryCode}`,
      url: '',
      fullname: city.fullName,
      locale: currentLocaleISO.value,
    };
  }
  await selectGlobalCity(newCity);
  closeModal();
};

const { apiClient } = useCabinetApi();
const toast = useToast();
const { t } = useI18n();

const citiesToSelect = ref<CdekCity[]>([]);

let topCitiesList: CdekCity[] = [];

onBeforeMount(async () => {
  try {
    topCitiesList = await restApi.websiteCityGlobal(websiteId.value, {
      locale: currentLocaleISO.value,
    });

    citiesToSelect.value = topCitiesList;
  } catch {
    toast.error({
      title: t('error'),
      text: 'Произошла ошибка, попробуйте позднее',
      withoutIcon: true,
    });
  }
});

const citiesAutocomplete = async (value: string) => {
  try {
    citiesToSelect.value = await apiClient.citiesAutocomplete(value);
  } catch (e) {
    toast.error({
      title: t('error'),
      text: 'Произошла ошибка, попробуйте позднее',
      withoutIcon: true,
    });
  }
};

const debouncedAutocomplete = debounce(citiesAutocomplete, 500);

const fetchItems = async (value: string) => {
  if (!value) {
    citiesToSelect.value = topCitiesList;
    return;
  }

  await debouncedAutocomplete(value);
};
</script>

<style scoped lang="scss">
.select-global-city-modal {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 44px);

  @include media-min-md {
    padding: 0 8px 24px;
  }

  &__input {
    padding: 24px 8px 16px;
    font-style: normal;

    @include media-min-md {
      padding: 0 16px 24px;
    }

    :deep(.cdek-input__tip:empty) {
      display: none;
    }

    :deep(.cdek-input__input) {
      @include body-2;
    }
  }

  &__city {
    padding: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    color: #000;
    padding-bottom: 6px;

    @include body-1;

    @include media-min-md {
      margin-bottom: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_green {
      font-weight: 500;
      color: #0a842f;
    }
  }

  &__cities-list {
    max-height: calc(100% - 76px);
    overflow: auto;
    padding: 0 13px 0 16px;

    @include thin-scrollbar;

    @include media-min-md {
      max-height: calc(304px);
    }
  }
}
</style>
